import useMiddlewareContext from './hooks/useMiddlewareContext';
import { AppRoutes } from '@/constants/Routes';

export default async () => {
  const { route, redirect } = useMiddlewareContext();

  if (route.path.includes(AppRoutes.TASK_CENTER)) {
    redirect(AppRoutes.DASHBOARD);
  }
};
