























































































































import {
  ref,
  watch,
  defineComponent,
  PropType,
  computed,
  useRouter,
  onMounted,
  ComputedRef,
} from '@nuxtjs/composition-api';
import { wizardActionState } from '@/modules/payroll/pages/employer/onboarding/OnboardingRoot.vue';
import usePayrollService from '@/modules/payroll/hooks/usePayrollService';
import PaySchedulePreview from '@/modules/payroll/pages/employer/onboarding/PaySchedulePreview';
import LoadingModal from '@/modules/payroll/components/LoadingModal/LoadingModal';
import format from 'date-fns/format';
import {
  formatDate,
  getFifteenthOfMonth,
  isFifteenthOfMonth,
  getDayOfMonth,
} from '@/utils/date';
import differenceInDays from 'date-fns/differenceInDays';
import isLastDayOfMonth from 'date-fns/isLastDayOfMonth';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import isWeekend from 'date-fns/isWeekend';
import addWeeks from 'date-fns/addWeeks';
import subWeeks from 'date-fns/subWeeks';
import subDays from 'date-fns/subDays';
import isFuture from 'date-fns/isFuture';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import addDays from 'date-fns/addDays';
import isValid from 'date-fns/isValid';
import isSameMonth from 'date-fns/isSameMonth';
import getMonth from 'date-fns/getMonth';
import isBefore from 'date-fns/isBefore';
import nextMonday from 'date-fns/nextMonday';
import nextTuesday from 'date-fns/nextTuesday';
import nextWednesday from 'date-fns/nextWednesday';
import nextThursday from 'date-fns/nextThursday';
import nextFriday from 'date-fns/nextFriday';
import startOfMonth from 'date-fns/startOfMonth';
import setDate from 'date-fns/setDate';

import Calendar from '@/modules/payroll/assets/Calendar.vue';
import DateFormat from '@/constants/DateFormat';
import {
  PayFrequency,
  PayDayOfWeek,
  PayDayOfSemiMonth,
} from '@/modules/payroll/constants/payroll';
import bam from '@/lib/bam';

import {
  TypeDisplay,
  TypeBody,
  BaseCard,
  BaseBanner,
  ModalDialog,
  AnnularThrobber,
} from '@bambeehr/pollen';
import PayScheduleForm, {
  PayScheduleWorkingForm,
} from '@/modules/payroll/components/PaySchedule/PayScheduleForm.vue';

import { trackEvent } from '@/modules/payroll/utils/track-apa';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import cloneDeep from 'lodash/cloneDeep';

import {
  useGetCompanyPaySchedulesQuery,
  Company,
  PaySchedule,
  useCreatePayScheduleMutation,
  TaskName,
  TaskCompletionStatus,
} from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import useGoals from '@/modules/TaskCenter/hooks/useGoals/useGoals';
import useBambeePlus, { nextYear } from '@/hooks/useBambeePlus/useBambeePlus';

export const emptyPaySchedule = {
  payFrequency: '',
  firstPayday: '',
  firstPeriodEnd: '',
  name: '',
  payDayOfWeek: null,
  payDayOfMonth: null,
};

// Exporting err consts for ease of testing
export const futureDateErr = 'Must be a future date';
export const semiMonthlyDateErr =
  'Semi-monthly paydays must fall on the 15th or last day of the month';
export const shortDateErr = 'Required';

function setWizardActionState(savePaySchedule, sendPayscheduleHelpRequest) {
  wizardActionState.value = {
    next: {
      label: 'Next',
      action: (next) => {
        savePaySchedule(() => next());
      },
    },
    finishLater: {
      label: 'Finish Later',
      action: (finishLater) => {
        finishLater();
      },
    },
    back: {
      label: 'Back',
      action: (back) => {
        back();
      },
    },
    skip: {
      label: 'Skip For Now',
      action: () => {
        sendPayscheduleHelpRequest();
      },
    },
    disableNext: false,
  };
}

function setNextBtnState(disableNext: boolean) {
  wizardActionState.value = {
    ...wizardActionState.value,
    disableNext,
    loadingNext: disableNext,
  };
}

// Our full date input always returns a 10 char date, ex: 01/03/2020
// so anything less than 10 chars would mean they left the date incomplete
export const isValidLength = (val: string): boolean => val?.length === 10;

// The date must be either today or a future date
export const isFutureDate = (val: string): boolean =>
  differenceInDays(new Date(val), new Date()) >= 0;

export const isBeforeThreeWeeks = (
  payday: string,
  todaysDate?: string
): boolean => {
  if (!isValidLength(payday)) {
    return false;
  }
  // Try/catch for date failures, though they shouldn't happen.
  // Logging the error but returning false since this is only a warning
  // Data will be validated by HRMs before going live
  try {
    return isBefore(
      new Date(payday),
      addWeeks(todaysDate ? new Date(todaysDate) : new Date(), 3)
    );
  } catch (error) {
    console.error(error);

    return false;
  }
};

const getPayPeriodEndOptions = (firstPayday: string) => {
  if (!firstPayday) {
    return [];
  }
  const startingDate = new Date(firstPayday);

  return Array.from({ length: 12 }, (_, i) =>
    format(subDays(startingDate, i), DateFormat.MM_DD_YYYY)
  ).reverse();
};

export const getDayFn = (payDayOfWeek: string) => {
  switch (payDayOfWeek) {
    case PayDayOfWeek.MONDAY.value:
      return nextMonday;
    case PayDayOfWeek.TUESDAY.value:
      return nextTuesday;
    case PayDayOfWeek.WEDNESDAY.value:
      return nextWednesday;
    case PayDayOfWeek.THURSDAY.value:
      return nextThursday;
    case PayDayOfWeek.FRIDAY.value:
    default:
      return nextFriday;
  }
};

export const getDefaultPaySchedule = (
  company,
  coreCompany
): PaySchedule | null => {
  if (!company) {
    return null;
  }
  const schedule: PayScheduleWorkingForm = company?.paySchedules?.find(
    (s) => s.default
  ) as PaySchedule;

  if (schedule) {
    schedule.firstPayday = formatDate(schedule.firstPayday, null);
    schedule.firstPeriodEnd = formatDate(schedule.firstPeriodEnd, null);
    // Setting to string because selectInput doesn't work with numbers
    schedule.payDayOfWeek = coreCompany.payDayOfWeek?.toString() || '';
    schedule.payDayOfMonth = coreCompany.payDayOfMonth?.toString() || '';
  }

  return (schedule as PayScheduleWorkingForm) || emptyPaySchedule;
};

export const getDateOrLastDayOfMonth = (
  date: Date,
  dayOfMonth: number
): Date => {
  const originalMonth = getMonth(date);
  let startDay = dayOfMonth;
  let newDate = setDate(date, startDay);

  while (originalMonth !== getMonth(newDate)) {
    startDay -= 1;
    newDate = setDate(date, startDay);
  }

  return newDate;
};

export const getMonthlyFirstPayDayOptions = (
  payDayOfMonth: string
): string[] => {
  const dayOfMonth = Number(payDayOfMonth);
  const startingDate = setDate(startOfMonth(new Date()), dayOfMonth);

  return Array.from({ length: 12 }, (_, i) => {
    const date = getDateOrLastDayOfMonth(
      addMonths(startingDate, i),
      dayOfMonth
    );

    // Verify that we have a 2 week buffer before the date
    return isFuture(subWeeks(date, 2)) && format(date, DateFormat.MM_DD_YYYY);
  }).filter(Boolean) as string[];
};

export const getSemiMonthlyFirstPaydayOptions = (
  payDayOfMonth: string
): string[] => {
  const [firstDay, secondDay] =
    Number(payDayOfMonth) === 1 ? [1, 15] : [15, 31];

  const firstStartDate = setDate(startOfMonth(new Date()), firstDay);
  const secondStartDate = getDateOrLastDayOfMonth(
    startOfMonth(new Date()),
    secondDay
  );

  const firstDayGroup = Array.from({ length: 8 }, (_, i) =>
    addMonths(firstStartDate, i)
  );
  const secondDayGroup = Array.from({ length: 8 }, (_, i) =>
    getDateOrLastDayOfMonth(addMonths(secondStartDate, i), secondDay)
  );

  return [...firstDayGroup, ...secondDayGroup]
    .sort((a, b) => (isBefore(a, b) ? -1 : 1))
    .map(
      (date) =>
        isFuture(subWeeks(date, 2)) && format(date, DateFormat.MM_DD_YYYY)
    )
    .filter(Boolean) as string[];
};

export const getWeeklyFirstPayDayOptions = (payDayOfWeek: string): string[] => {
  const dayFn = getDayFn(payDayOfWeek);
  const startingDate = addWeeks(dayFn(new Date()), 2);

  return Array.from({ length: 12 }, (_, i) =>
    format(addWeeks(startingDate, i), DateFormat.MM_DD_YYYY)
  );
};

export const getSecondPayDate = (schedule) => {
  const { payDayOfMonth, firstPayday } = schedule;
  if (!payDayOfMonth || !firstPayday) {
    return undefined;
  }

  const parsedFirstPayDay = new Date(firstPayday);
  let date;

  if (payDayOfMonth === PayDayOfSemiMonth.FIRST_AND_MID.value) {
    // 1st and 15th of month
    if (isFirstDayOfMonth(parsedFirstPayDay)) {
      // If the first pay day is the 1st, set the 15th of that month as the second pay day
      date = setDate(parsedFirstPayDay, 15);
    } else {
      // If the 15th is the first pay day, set to the 1st of the next month
      date = startOfMonth(addMonths(parsedFirstPayDay, 1));
    }
  } else if (!isFifteenthOfMonth(parsedFirstPayDay)) {
    // If the last day of the month is the first pay day, set to the 15th of the next month
    date = setDate(addMonths(parsedFirstPayDay, 1), 15);
  }

  return date ? format(date, DateFormat.YYYY_MM_DD) : undefined;
};

const getFirstPayDayOptions = (
  payDayOfWeek: string,
  payDayOfMonth: string,
  payFrequency: string
): string[] => {
  switch (payFrequency) {
    case PayFrequency.SEMIMONTHLY.value:
      return getSemiMonthlyFirstPaydayOptions(payDayOfMonth);

    case PayFrequency.MONTHLY.value:
      return getMonthlyFirstPayDayOptions(payDayOfMonth);

    default:
      // Weekly and Biweekly
      return getWeeklyFirstPayDayOptions(payDayOfWeek);
  }
};

export default defineComponent({
  name: 'PayScheduleCreate',
  components: {
    BaseBanner,
    BaseCard,
    PayScheduleForm,
    PaySchedulePreview,
    TypeBody,
    TypeDisplay,
    ModalDialog,
    LoadingModal,
    Calendar,
    AnnularThrobber,
  },
  props: {
    companyId: {
      type: String as PropType<string>,
    },
    shouldSave: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { updateCompany } = usePayrollService();
    const router = useRouter();
    const { shouldStartPayrollNextYear } = useBambeePlus();

    const isLoading = ref<boolean>(false);
    const paydayErr = ref<string>('');
    const periodEndErr = ref<string>('');
    const payDayOfErr = ref<string>('');
    const payFreqErr = ref<string | null>(null);
    const isSaving = ref<boolean>(false);
    const showLoadingModal = ref<boolean>(false);
    const payScheduleError = ref<any>(null); // Coming from check errs, so any
    const companyRes = ref();
    const company = ref<Company>();

    const todaysDate = format(new Date(), DateFormat.E_MMM_DO_YYYYY);

    const { onDone: doneCreatingPaySchedule, mutate: createPaySchedule } =
      useApolloMutation(useCreatePayScheduleMutation, { pending: isSaving });

    function getCompanyData(callBack?: Function) {
      const { onResult } = useApolloQuery(
        useGetCompanyPaySchedulesQuery,
        { id: props.companyId as string },
        {
          data: companyRes,
          pending: isLoading,
        },
        undefined,
        // Always pull from network
        { fetchPolicy: CachePolicy.NETWORK_ONLY }
      );

      const unwatch = onResult(({ getCompany }) => {
        company.value = getCompany as Company;

        if (callBack) {
          callBack();
        }

        if (unwatch) {
          unwatch();
        }
        showLoadingModal.value = false;
      });
    }

    getCompanyData();

    // @ts-ignore, until we add dayOfWeek to the PaySchedule type
    const paySchedule = ref<PayScheduleWorkingForm>(emptyPaySchedule);

    watch(
      companyRes,
      ({ getCompany: payrollCompany, getCoreCompany: coreCompany }) => {
        if (payrollCompany?.needsPayScheduleAssistance) {
          updateCompany(
            {
              needsPayScheduleAssistance: false,
              id: props.companyId as string,
            },
            {}
          );
        }
        paySchedule.value = getDefaultPaySchedule(
          cloneDeep(payrollCompany),
          cloneDeep(coreCompany)
        ) as PayScheduleWorkingForm;
      }
    );

    const paydayIsTooSoon = computed(() =>
      isBeforeThreeWeeks(paySchedule.value.firstPayday)
    );

    const shouldWaitUntilNextYear: ComputedRef<Boolean> = computed(
      () =>
        shouldStartPayrollNextYear.value &&
        new Date(paySchedule.value?.firstPayday)?.getTime() < nextYear.getTime()
    );

    const paydayIsSameAsPeriodEnd = computed(() =>
      isSameDay(
        new Date(paySchedule.value.firstPayday),
        new Date(paySchedule.value.firstPeriodEnd)
      )
    );

    const formattedPayScheduleError = computed(() => {
      const inputErr =
        payScheduleError.value?.raw?.extensions?.exception?.inputErrors[0];

      return inputErr ? `${inputErr.field}: ${inputErr.message}` : '';
    });

    const isWeekly = computed(
      () => paySchedule.value.payFrequency === PayFrequency.WEEKLY.value
    );

    const isBiWeekly = computed(
      () => paySchedule.value.payFrequency === PayFrequency.BIWEEKLY.value
    );

    const isSemiMonthly = computed(
      () => paySchedule.value.payFrequency === PayFrequency.SEMIMONTHLY.value
    );

    const isMonthly = computed(
      () => paySchedule.value.payFrequency === PayFrequency.MONTHLY.value
    );

    const showFirstPayday = computed(
      () => isSemiMonthly.value && !!paySchedule.value.firstPeriodEnd
    );

    const firstPeriodHasMatchingDays = computed(
      () => paySchedule.value.firstPayday === paySchedule.value.firstPeriodEnd
    );

    const firstPaydayOptions = computed(() =>
      getFirstPayDayOptions(
        paySchedule.value.payDayOfWeek as string,
        paySchedule.value.payDayOfMonth as string,
        paySchedule.value.payFrequency
      )
    );

    const firstPayPeriodEndOptions = computed(() =>
      getPayPeriodEndOptions(paySchedule.value?.firstPayday)
    );

    const secondPeriodEnd = computed(() => {
      if (
        !isSemiMonthly.value ||
        !paySchedule.value.firstPeriodEnd ||
        !isValid(new Date(paySchedule.value.firstPeriodEnd))
      ) {
        return null;
      }

      const firstPeriodEnd = new Date(paySchedule.value.firstPeriodEnd);
      const isEndOfMonth = isLastDayOfMonth(firstPeriodEnd);

      if (isEndOfMonth) {
        return getFifteenthOfMonth(addMonths(firstPeriodEnd, 1));
      }

      return lastDayOfMonth(firstPeriodEnd);
    });

    function formIsValid(): boolean {
      periodEndErr.value = '';
      paydayErr.value = '';
      payDayOfErr.value = '';
      payFreqErr.value = null;

      if (!paySchedule.value.firstPeriodEnd) {
        periodEndErr.value = shortDateErr;
      }

      if (!paySchedule.value.firstPayday) {
        paydayErr.value = shortDateErr;
      }

      if (
        !(paySchedule.value.payDayOfWeek || paySchedule.value.payDayOfMonth)
      ) {
        payDayOfErr.value = shortDateErr;
      }

      // Note: We need to allow the last day of pay period to be in the past
      if (!paydayErr.value && !isFutureDate(paySchedule.value.firstPayday)) {
        paydayErr.value = futureDateErr;
      }

      if (!paySchedule.value.payFrequency) {
        payFreqErr.value = shortDateErr;
      }

      if (
        !paydayErr.value &&
        !isSemiMonthly.value &&
        !isMonthly.value &&
        isWeekend(new Date(paySchedule.value.firstPayday))
      ) {
        paydayErr.value = 'Desired first pay date cannot be on a weekend';
      }

      return (
        !periodEndErr.value &&
        !paydayErr.value &&
        !payDayOfErr.value &&
        !payFreqErr.value &&
        !shouldWaitUntilNextYear.value
      );
    }

    const secondPayday = computed(() => getSecondPayDate(paySchedule.value));

    function updatePaySchedule(event) {
      // If they selected something that isn't in the options, clear out the value
      // This happens when they change their selection of payday.
      // The period end could exist in both, so try to preserve it if possible.
      if (!firstPayPeriodEndOptions.value.includes(event.firstPeriodEnd)) {
        event.firstPeriodEnd = '';
      }

      paySchedule.value = event;
    }

    function savePaySchedule(callback?: () => void, sendSavingEmit = false) {
      if (formIsValid()) {
        if (sendSavingEmit) {
          emit('saving');
        }
        createPaySchedule({
          coreData: {
            id: props.companyId as string,
            payDayOfWeek: paySchedule.value.payDayOfWeek
              ? Number(paySchedule.value.payDayOfWeek)
              : undefined,
            payDayOfMonth: paySchedule.value.payDayOfMonth
              ? Number(paySchedule.value.payDayOfMonth)
              : undefined,
          },
          data: {
            companyId: props.companyId as string,
            name: paySchedule.value.name,
            payFrequency: paySchedule.value.payFrequency,
            firstPayday: format(
              new Date(paySchedule.value.firstPayday),
              DateFormat.YYYY_MM_DD
            ),
            firstPeriodEnd: format(
              new Date(paySchedule.value.firstPeriodEnd),
              DateFormat.YYYY_MM_DD
            ),
            secondPayday:
              isSemiMonthly.value && secondPayday.value
                ? secondPayday.value
                : undefined,
          },
        });

        doneCreatingPaySchedule(() => {
          // Persist loading statue until company data is fetched
          isSaving.value = true;

          getCompanyData(() => {
            if (callback) {
              callback();
            }
            isSaving.value = false;
          });
        });
      }
    }

    function sendPayscheduleHelpRequest() {
      const { data } = updateCompany(
        {
          needsPayScheduleAssistance: true,
          id: props.companyId as string,
        },
        {}
      );

      const unwatch = watch(data, () => {
        bam.track('payroll-employer-onboarding-payschedule-skipped', {
          companyId: props.companyId,
        });
        router.push('/payroll-setup/bank-tax');
        unwatch();
      });
    }

    setWizardActionState(savePaySchedule, sendPayscheduleHelpRequest);

    watch(isSaving, (shouldDiable) => {
      setNextBtnState(shouldDiable && !showLoadingModal.value);
    });

    // Reset top position
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    // APA Tracking
    const { isOnboarding } = usePayrollOnboardingStatus();

    if (isOnboarding.value) {
      trackEvent('payroll-onboarding-pay-schedule-create');
    }

    const showPaySchedulePreview = ref(false);
    const togglePaySchedulePreview = () => {
      // In order to preview a pay schedule we need to actually save it to the company.
      if (!showPaySchedulePreview.value) {
        showLoadingModal.value = true;
        savePaySchedule(() => {
          showPaySchedulePreview.value = true;
        });

        return;
      }

      showPaySchedulePreview.value = false;
    };

    const attemptSave = computed(() => props.shouldSave);

    const { completeTaskByName } = useGoals();
    watch(attemptSave, (shouldSave) => {
      if (shouldSave) {
        emit('reset');
        savePaySchedule(() => {
          completeTaskByName(
            TaskName.SetYourPayrollFrequency,
            TaskCompletionStatus.Manual
          );
          emit('saved');
        }, true);
      }
    });

    return {
      isLoading,
      company,
      firstPaydayOptions,
      firstPayPeriodEndOptions,
      formattedPayScheduleError,
      formIsValid,
      isSemiMonthly,
      paydayErr,
      payDayOfErr,
      paydayIsTooSoon,
      paySchedule,
      periodEndErr,
      payFreqErr,
      showFirstPayday,
      updatePaySchedule,
      secondPeriodEnd,
      isWeekly,
      isBiWeekly,
      isMonthly,
      showLoadingModal,
      togglePaySchedulePreview,
      showPaySchedulePreview,
      isSaving,
      todaysDate,
      paydayIsSameAsPeriodEnd,
      shouldWaitUntilNextYear,
    };
  },
});
