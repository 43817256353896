import { validateRolePermissions } from '@/modules/payroll/middleware/employerPayrollGuard';
import { getCompanyStatus } from '@/gql/services/payroll.service';
import { getUserPreference } from '@/utils/userPreference';
import LocalStorageKey from '@/constants/LocalStorage';
import isCompanyAdmin from '@/helpers/accessControl/isCompanyAdmin';

enum Routes {
  ROOT = '/time-and-attendance',
  UPSELL = '/get-time-and-attendance',
  PENDING = '/get-time-and-attendance/processing',
}

export enum DeputyEdition {
  TRIAL = 'SUBSCRIPTION_PRO_TRIAL',
  PAID = 'SUBSCRIPTION_PAID_PRO_PREMIUM',
  FREE = 'SUBSCRIPTION_FREE',
}

const validateCompanyStatus = async (store) => {
  const { companyId } = store.getters;

  // State is cached via apollo-client
  const { data: company } = await getCompanyStatus(companyId, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const isEligible = !!company?.id;
  const isActive = !!company?.deputyConnection?.hasAccessToken;
  const isProcessing =
    !company?.deputyConnection?.hasAccessToken &&
    company?.deputyConnection?.installId;
  const edition = company?.deputyConnection?.edition;

  return {
    isActive,
    isEligible,
    isProcessing,
    edition,
  };
};

export const canAccess = async (store) => {
  const hasValidCompanyStatus = (await validateCompanyStatus(store)).isActive;
  const hasRolePermissions = await validateRolePermissions(store, true);

  return hasValidCompanyStatus && hasRolePermissions;
};

export const canAccessUpsell = async (store) => {
  const companyStatus = await validateCompanyStatus(store);
  const hasRolePermissions = await validateRolePermissions(store, true);

  return (
    !companyStatus.isActive && companyStatus.isEligible && hasRolePermissions
  );
};

export const hasDeputyPremiumPro = async (store) => {
  const { edition } = await validateCompanyStatus(store);

  return edition === DeputyEdition.PAID;
};

export const guard = async ({ redirect, store, route }) => {
  const status = await validateCompanyStatus(store);
  // Trial is not a valid status for T&A in "the real world" but could happen if there's a failure on the Deputy side
  // Adding trial here helps to prevent the user from being stuck in the pending page
  const hasPremiumPro =
    status.edition === DeputyEdition.PAID ||
    status.edition === DeputyEdition.TRIAL;

  const hasFree = status.edition === DeputyEdition.FREE;

  // If navigating to the primary T&A page, but not setup, redirect to the upsell page
  if (route.path === Routes.ROOT && (!status.isActive || !hasPremiumPro)) {
    redirect(Routes.UPSELL);
  }

  const isUpsell = route.path === Routes.UPSELL;
  const isPending = route.path === Routes.PENDING;
  if (isUpsell || isPending) {
    // If they do have access to T&A, don't let them go back to the upsell or pending page
    if (status.isActive && hasPremiumPro) {
      redirect(Routes.ROOT);
    }
  }

  // Cookie that's set to know if a user has just purchased T&A
  // There is about a 1 min delay between purchase and when the user can access T&A (deputy latency)
  const { companyId } = store.getters;
  const hasPurchased = getUserPreference(
    `${companyId}:${LocalStorageKey.PURCHASED_TIME_AND_ATTENDANCE}`
  );
  if (isUpsell && hasPurchased && !hasFree) {
    redirect(Routes.PENDING);
  }

  if (isPending && !hasPurchased) {
    redirect(Routes.UPSELL);
  }
};

export default guard;
