export const WhiteListedRoutes = Object.freeze({
  LOGIN: '/login',
  LOGIN_TRAILING: '/login/',
  LOGIN_FORGOT_PASS: '/login/forgot-pass',
  LOGIN_FORGOT_PASS_TRAILING: '/login/forgot-pass/',
  RESET: '/reset',
  RESET_TRAILING: '/reset/',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_TRAILING: '/reset-password/',
  REGISTRATION: `//${process.env.www}/registration`,
  ACTIVATE_ACCOUNT: '/activate-account',
  ACTIVATE_ACCOUNT_TRAILING: '/activate-account/',
  C: '/c/',
  ACTIVATION: '/activation',
  ACTIVATION_TRAILING: '/activation/',
  ACTIVATION_EXPIRED: '/activation-expired',
  ACTIVATION_EXPIRED_TRAILING: '/activation-expired/',
  PAYMENT: '/payment',
});

export const APIRoutes = Object.freeze({
  REFRESH_TOKEN: '/auth/v3/refresh',
  CONSUME_TOKEN: '/auth/v3/token/consume',
});

export const AppRoutes = Object.freeze({
  DASHBOARD: '/dashboard',
  TASK_CENTER: '/task-center',
  PAYROLL: '/payroll',
});

const Routes = { ...WhiteListedRoutes };
export default Routes;
