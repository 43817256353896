import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import {
  GetCompanyOnboardingStatusQuery,
  useCancelTimeAttendanceMutation,
  useGetCompanyStatusQuery,
} from '@/gql/generated';
import { GET_COMPANY_STATUS } from '@/gql/queries/payroll_queries.gql';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import useCurrentUser from '@/hooks/useCurrentUser';
import bam from '@/lib/bam';
import useNotifications from '@bambeehr/use-notifications';
import {
  computed,
  reactive,
  ref,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';

const trackPrefix = `cancel_time_and_attendance`;

const unpaidEdition = 'SUBSCRIPTION_FREE';

let addSuccess;
let addError;
let router;

const cancellationTrackEvents = {
  MODAL_TOGGLE: `${trackPrefix}_modal_toggle`,
  SUBMIT: `${trackPrefix}_submit`,
  CANCELLATION_ERROR: `${trackPrefix}_error`,
  CANCELLATION_SUCCESS: `${trackPrefix}_success`,
};

const company = ref<GetCompanyOnboardingStatusQuery>();
const isLoading = ref(false);

const companyDetails = reactive({
  companyId: '',
  currentUserId: '',
});

const showCancelTimeAndAttendanceModal = ref(false);

const toggleCancelTimeAndAttendanceModal = () => {
  bam.trackEvent(cancellationTrackEvents.MODAL_TOGGLE, {
    isModalOpen: !!showCancelTimeAndAttendanceModal.value,
  });
  showCancelTimeAndAttendanceModal.value =
    !showCancelTimeAndAttendanceModal.value;
};

const hasTimeAndAttendance = computed(
  () =>
    !!company.value?.getCompany?.deputyConnection?.hasAccessToken &&
    !!company.value?.getCompany?.deputyConnection?.validatedAt
);

const hasALaCarteTimeAndAttendance = computed(
  () =>
    !!company.value?.getCompany?.deputyConnection?.hasAccessToken &&
    !!company.value?.getCompany?.deputyConnection?.validatedAt &&
    company.value?.getCompany?.deputyConnection?.canCancel &&
    // unpaid edition does not get access to T&A
    company.value?.getCompany?.deputyConnection?.edition !== unpaidEdition
);

const cancelTimeAndAttendance = () => {
  bam.trackEvent(cancellationTrackEvents.SUBMIT, {
    companyId: companyDetails.companyId,
    currentUserId: companyDetails.currentUserId,
  });
  isLoading.value = true;
  const { mutate, onError, onDone } = useApolloMutation(
    useCancelTimeAttendanceMutation
  );

  mutate(
    { data: companyDetails },
    {
      refetchQueries: [
        {
          query: GET_COMPANY_STATUS,
          variables: { id: companyDetails.companyId },
        },
      ],
    }
  );

  onError((error) => {
    window.DD_RUM?.addError(error);
    bam.trackEvent(cancellationTrackEvents.CANCELLATION_ERROR, {
      companyId: companyDetails.companyId,
      currentUserId: companyDetails.currentUserId,
      error,
    });
    toggleCancelTimeAndAttendanceModal();
    addError('An error occurred while cancelling Time and Attendance.');
    isLoading.value = false;
  });

  onDone((res) => {
    bam.trackEvent(cancellationTrackEvents.CANCELLATION_SUCCESS, {
      companyId: companyDetails.companyId,
      currentUserId: companyDetails.currentUserId,
    });
    // Wait 3 seconds for the cancellation to take effect
    setTimeout(() => {
      toggleCancelTimeAndAttendanceModal();
      addSuccess('Time and Attendance has been successfully cancelled.');
      isLoading.value = false;
      router.push('/time-and-attendance');
    }, 5000);
  });
};

const getCompanyStatus = () => {
  const { companyId } = useCurrentCompany();
  const { currentUserId } = useCurrentUser();
  const tmpRouter = useRouter();
  const { addSuccess: tmpAddSuccess, addError: tmpAddError } =
    useNotifications();
  addSuccess = tmpAddSuccess;
  addError = tmpAddError;
  router = tmpRouter;

  companyDetails.companyId = companyId.value;
  companyDetails.currentUserId = currentUserId.value;

  watch(
    companyId,
    (id) => {
      if (id) {
        useApolloQuery(
          useGetCompanyStatusQuery,
          { id },
          { data: company },
          // Silent fail if payroll company is not found.
          { handleErrors: false }
        );
      }
    },
    { immediate: true }
  );
};

const useEmployerTimeAndAttendanceAccess = () => {
  getCompanyStatus();

  return {
    hasTimeAndAttendance,
    hasALaCarteTimeAndAttendance,
    showCancelTimeAndAttendanceModal,
    toggleCancelTimeAndAttendanceModal,
    cancelTimeAndAttendance,
    isLoading,
  };
};

export default useEmployerTimeAndAttendanceAccess;
