// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';

import { isAnUnpaidAppPath } from '@/modules/Leads/utils/Routing';
import activationMiddleware from './activation';
import allowUnpaidAppAuthTokensMiddleware from './allowUnpaidAppAuthTokens';
import authMiddleware from './auth';
import useMiddlewareContext, {
  MiddlewareContext,
} from './hooks/useMiddlewareContext';
import nonPaidMiddleware from './non-paid';
import passwordResetMiddlware from './password-reset';
import redirectIfDelinquentMiddleware from './redirectIfDelinquent';
import termsOfServiceNotificationMiddleware from './termsOfServiceNotification';
import checkForAppUpdatesMiddleware from './checkForAppUpdatesMiddleware';
import { WhiteListedRoutes } from '@/constants/Routes';
import SessionStorage from '@/constants/SessionStorage';
import bambeePlusStatus from '@/modules/BambeePlus/utils/bambeePlusStatus';
import hasAdminPermissions from '@/helpers/accessControl/hasAdminPermissions';
import handleOnboardingRouting from './handleOnboardingRouting';
import toRedirString from '@/utils/toRedirString';
import featureLogging from '@/middleware/featureLogging';
import applicationRedirects from '@/middleware/applicationRedirects';

export default async ({ store, redirect, route, app }: Context) => {
  const currentUser = store.state?.current_user;
  const isAdmin = hasAdminPermissions();

  if (!currentUser) {
    const isWhiteListedRoute = Object.values(WhiteListedRoutes).some((path) =>
      route.path.includes(path)
    );

    // if the user is not logged in, and the current path is not a whitelisted route, redirect to the login page
    if (!isWhiteListedRoute) {
      const redirString = toRedirString(route.path, route.query);

      // The redirect method here isn't functioning as expected.
      // It should redirect to the login page with the current path as a query parameter.
      // Using a hard redirect instead to cut out the looping bug and ensure the user is redirected to the login page with the original path as a redir query parameter.
      window.location.href = `${WhiteListedRoutes.LOGIN}${
        redirString ? `?redir=${redirString}` : ''
      }`;
    }

    const expiredActivationToken = window.sessionStorage.getItem(
      SessionStorage.EXPIRED_ACTIVATION_TOKEN
    );
    if (
      route.path !== WhiteListedRoutes.ACTIVATION_EXPIRED &&
      expiredActivationToken
    ) {
      redirect(WhiteListedRoutes.ACTIVATION_EXPIRED);
    }

    return;
  }
  const { role, _auth, _company: company } = currentUser;

  if (isAdmin) {
    // Make sure necessary data is available before continuing
    await bambeePlusStatus(company._id);
  }

  const currentPath = route.path;

  const middlewareContext = {
    store,
    redirect,
    route,
    app,
    currentUser,
    role,
    _auth,
  } as MiddlewareContext;

  // sets up the context for each middleware
  useMiddlewareContext(middlewareContext);

  // Logging feature usage based on urls
  featureLogging();

  // checkForAppUpdatesMiddleware: checks if current version of app is out of date
  // * if the app is out of date then redirect to the current path via a "hard" redirect
  checkForAppUpdatesMiddleware();

  // * checks valid unpaid app paths
  if (isAnUnpaidAppPath(currentPath)) {
    // allowUnpaidAppAuthTokensMiddleware: allows unpaid app users to access the app
    // * checks if access token is on the query params or in the cookie
    // * if the token is found and is NOT valid(expired) get a refresh token
    // * using the refresh token assert a valid token using the assertValidToken function
    allowUnpaidAppAuthTokensMiddleware();
  } else {
    // authMiddleware: redirects new users to either the "/activation-expired", "/registration" or "/login" pages based on
    // if the activation token is expired or if they don't have a company
    // * first checks a list of white listed paths ( any path that doesn't require authentication )
    // * if the current path includes "/activation-expired" and the activation token is NOT expired then redirect to the "/login" page
    // * otherwise do not run the auth middleware

    // * if the activation token is expired then redirect to the "/activation-expired" page

    // * if current user exists but has no company then redirect to //${process.env.www}/registration`
    // * otherwise do not run the auth middleware

    // * if none of the above conditions are met then deleteCookie("access-token")
    // * set app.$axios.setToken(false)
    // * redirect to the "/login" with a redir string of the current path and query params
    authMiddleware();

    // activationMiddleware: redirects users to the activation page if they are not activated
    // * first checks if the users activation status includes 'created' or 'email-sent'
    // * then if current path includes one of the following paths then do not run the activation middleware:
    // * 1. '/privacy/privacy',
    // * 2. '/terms/terms',
    // * 3. '/employee-welcome/',
    // * 4. '/activate-account',

    // * if the current user role is 'user' then redirect to the '/activate-account' page

    // * otherwise redirect to the '/employee-welcome/' page
    activationMiddleware();
  }

  // redirectIfDelinquentMiddleware: redirects users who are long standing delinquents to the billing page
  // * will NOT redirect if the following conditions are met:
  // * The user is not an admin
  // * OR The feature flag is NOT enabled (LOCK_LONG_STANDING_DELINQUENTS)
  // * OR The current path is a blacklisted path

  // * otherwise if the user is a long standing delinquent, redirect to the `/settings#billing` page
  redirectIfDelinquentMiddleware();

  // termsOfServiceNotificationMiddleware: sets the store state "showedTermsOfService" value to true if the user has NOT accepted the terms of service
  // * if current user role is NOT 'user' then do not run the termsOfServiceNotificationMiddleware
  // * if current user has accepted the terms of service then do not run the termsOfServiceNotificationMiddleware
  // * if the store state "showedTermsOfService" value is true then do NOT run the termsOfServiceNotificationMiddleware

  // * otherwise commit "showTermsOfService" with value of true to the vuex store
  termsOfServiceNotificationMiddleware();

  // handleOnboardingRouting: handles the onboarding routing based on the company status
  // * if the current user has not begun either the legacy or new onboarding process and they're a trial customer, determine if they should use the test group or control.
  // -- * if test, send the user to the new onboarding flow
  // -- * if control, send the user to the legacy onboarding flow
  // * if the user has begun either of the onboarding flows
  // -- * if the user has completed the legacy onboarding flow, send the user to the dashboard. Otherwise send them back to the onboarding flow.
  // -- * if the user has completed the new onboarding flow, send the user to the dashboard. Otherwise send them back to the new onboarding flow.

  // * if either company onboarding flow has been completed then allow user to pass through guard
  await handleOnboardingRouting();

  // passwordResetMiddlware: handles the password reset flow
  // * if activation_status is 'password-reset'

  // * if current path includes "reset-password"
  // * then do NOT run the passwordResetMiddlware

  // * if the current path includes "/reset-password/"
  // * then do NOT run the passwordResetMiddleware

  // * if the above conditions are NOT met then redirect to the "/reset-password/" page
  passwordResetMiddlware();

  // nonPaidMiddleware: redirects users to the about-bambee page if they are not paid otherwisee redirects them to the task center
  // * if current company status is 'trial', 'paid', or 'paying' and current path includes "/about-bambee"
  // * then redirect to the "/task-center" page

  // * if current path includes the white listed paths then do NOT run the nonPaidMiddleware
  // * if paid do NOT run the nonPaidMiddleware

  // * if company status is 'inactive' AND user role is 'user' (this means they are unpaid)
  // * then redirect to the "/get-bambee" page

  // * if current path is equal to "/payment" AND upaid AND user role is NOT 'user' OR 'company-owner'
  // * then redirect to the "/about-bambee/get-bambee" page

  // * if current route query['immediate-request'] is true
  // * then redirect to the "/about-bambee?immediate-request=true" page with query params

  // * if the above conditions are NOT met redirect to the "/about-bambee" page
  nonPaidMiddleware();

  // * General site redirects for deprecated routes
  applicationRedirects();
};
